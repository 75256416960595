<template>
  <div class="wedding">
    <button class="wedding__music-btn" @click="musicOn">
      <lord-icon
        src="https://cdn.lordicon.com/yvsfcbjq.json"
        trigger="hover"
        colors="primary:#0a2e5c,secondary:#242424"
        style="width:22px;height:22px">
      </lord-icon>
    </button>
    <audio>
      <source src="../assets/audio/wedMusic.mp3"  />
    </audio>
    <div class="main">
      <div class="container">
        <div class="main__image-wrapper">
          <transition name="fade">
          <div v-if="isNamesShown" class="main__text main__text--top">
            A&A
          </div>
          </transition>
          <video class="main__image" src="../assets/video/webBg.mp4" playsinline autoplay loop muted/>
          <transition name="fade">
          <div v-if="isNamesShown" class="main__text main__text--bottom">
            Anna & Artem
            <div class="main__date">
              26 июля 2026
            </div>
          </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="description">
      <div class="container">
        <h2 data-aos="fade-right" data-aos-duration="2000">
          О нас
          <div class="h2__hr"/>
        </h2>
        <div class="description__text">
          Мы счастливы объявить, что скоро состоится наша свадьба, и мы хотели бы, чтобы вы были частью этого важного события в нашей жизни. Присоединяйтесь к нам в этот особенный день, чтобы разделить радость и любовь
        </div>
        <div class="description__image-wrapper">
          <img class="description__image" src="../assets/images/wedding.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="schedule">
      <div class="container">
        <h2 data-aos="fade-right" data-aos-duration="2000">
          План торжества
          <div class="h2__hr"/>
        </h2>
        <div class="schedule__content">
          <div v-for="(item, index) in schedule" :key="index" class="schedule__item">
            <div class="schedule__item-icon">
              <lord-icon
                v-if="item.iconName === 'toast'"
                src="https://cdn.lordicon.com/adsnhspx.json"
                trigger="loop"
                delay="3000"
                style="width:140px;height:140px">
              </lord-icon>
              <lord-icon
                v-if="item.iconName === 'wedding'"
                src="https://cdn.lordicon.com/cqofjexf.json"
                trigger="loop"
                delay="3000"
                style="width:140px;height:140px">
              </lord-icon>
              <lord-icon
                v-if="item.iconName === 'location'"
                src="https://cdn.lordicon.com/iikoxwld.json"
                trigger="loop"
                delay="4000"
                colors="primary:lightblue"
                style="width:140px;height:140px">
              </lord-icon>
            </div>
            <div class="schedule__item-title">
              {{ item.title }}
            </div>
            <div class="schedule__item-text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="location">
      <div class="container">
        <h2 data-aos="fade-right" data-aos-duration="2000">
          Где?
          <div class="h2__hr"/>
        </h2>
        <div class="location__text hidden">
          Наше торжество будет проходить в Банкетном зале <b>"Кухтеринъ"</b> по адресу:
          г. Томск, пер. Нахановича, 9.
        </div>
      </div>
      <div class="location__map">
        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Abc452bed8461898ae854b02fc2aaf5d1d1cd24d0866bd8230067c6718fd17f72&amp;source=constructor" width="100%" height="580" frameborder="0"></iframe>
      </div>
    </div>
    <div class="dress-code">
      <div class="container">
        <h2 data-aos="fade-right" data-aos-duration="2000">
          Дресс-код
          <div class="h2__hr"/>
        </h2>
        <div class="dress-code__wrapper">
          <div class="dress-code__text">
            Познакомимся с цветовой гаммой нашего торжества для дам:
          </div>
          <div data-aos="fade-right" data-aos-duration="2000" class="dress-code__colors">
            <div v-for="(item, index) in colors" :key="index" class="dress-code__color-item" :style="{'background': item.color}" />
          </div>
          <div class="dress-code__text">
            для мужчин:
          </div>
          <div data-aos="fade-right" data-aos-duration="2000" class="dress-code__colors">
            <div v-for="(item, index) in mensColors" :key="index" class="dress-code__color-item" :style="{'background': item.color}" />
          </div>
        </div>
      </div>
    </div>
    <div class="survey">
      <div class="container">
        <div class="survey__content">
          <h2 data-aos="fade-right" data-aos-duration="2000">
            Анкета гостя
            <div class="h2__hr"/>
          </h2>
          <el-form ref="formRef" id="form">
            <el-form-item name="name" label="Имя" aria-placeholder="Олег Петров, Анна Петрова" prop="name">
              <el-input class="survey__input" v-model="formModel.name" placeholder="Иванов Петр, Иванова Ирина" />
            </el-form-item>
            <el-form-item name="isCome" label="Сможете ли вы прийти?" prop="isCome">
              <el-radio-group v-model="formModel.isCome">
                <el-radio :value="'Буду'">Да, с радостью</el-radio>
                <el-radio :value="'Не буду'">К сожалению, не получится</el-radio>
                <el-radio :value="'Возможно буду'">Пока не могу точно ответить</el-radio>
              </el-radio-group>
            </el-form-item>
            <transition name="slide-fade">
            <el-form-item v-if="formModel.isCome !== 'Не буду'" name="music" label="Любимая музыкальная композиция" prop="music">
              <el-input class="survey__input" v-model="formModel.music" />
            </el-form-item>
            </transition>
            <el-form-item name="hasChildren" label="Будут ли с вами дети?" prop="hasChildren">
              <el-radio-group v-model="formModel.hasChildren">
                <el-radio :value="'С детьми'">Да, будем с детишками</el-radio>
                <el-radio :value="'Без детей'">Нет, будем одни</el-radio>
              </el-radio-group>
            </el-form-item>
            <transition name="slide-fade">
              <el-form-item v-if="formModel.hasChildren === 'С детьми'" name="children" label="Сколько детей будет?" prop="children">
                <el-input-number v-model="formModel.children" :min="1" :max="10" />
              </el-form-item>
            </transition>
            <el-form-item name="commentary" label="Комментарии" prop="commentary">
              <el-input type="textarea" v-model="formModel.commentary" :maxlength="140"/>
            </el-form-item>
            <button
              @click="handleSendData"
              :disabled="isButtonDisabled"
              v-loading="isButtonDisabled"
              element-loading-background="rgba(122, 122, 122, 0.8)"
              :element-loading-spinner="svg"
              element-loading-svg-view-box="-10, -10, 50, 50"
              type="submit"
              class="survey__form-button"
            >
              Отправить
            </button>
          </el-form>
        </div>
      </div>
    </div>
    <div class="questions">
      <div class="container">
        <div class="questions__content">
          <h2 data-aos="fade-right" data-aos-duration="2000">
            Вопросы
            <div class="h2__hr"/>
          </h2>
          <div class="questions__collapse">
            <el-collapse v-model="activeNames">
              <el-collapse-item v-for="(item, index) in questionsArr" :key="index" :name="index">
                <template #title>
                  <div>
                    {{ item.title }}
                  </div>
                </template>
                <div v-html="item.text" />
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="time">
      <div class="container">
        <div class="time__content">
          <h2 data-aos="fade-right" data-aos-duration="2000">
            До торжества осталось:
            <div class="h2__hr"/>
          </h2>
          <Timer :date="new Date('2026-06-26T00:00:00')"/>
        </div>
      </div>
    </div>
    </div>

</template>

<script setup>
import { onMounted, ref, reactive } from "vue";
import Timer from "@/components/Timer"
import AOS from 'aos'
import { MockQuestionsArr, mockSchedule } from '@/constants/mocks'
import { loaderSvg } from "@/constants/loader";
import { showSuccess } from "@/utils/notification";

const isMusicOn = ref(false)
const isNamesShown = ref(false)
const isButtonDisabled = ref(false)
const activeNames = ref([''])
const formRef = ref()
const formModel = reactive({
  name: '',
  music: '',
  isCome: 'Буду',
  hasChildren: 'С детьми',
  children: 1,
  commentary: ''
})
const URL_APP = 'https://script.google.com/macros/s/AKfycbz2bKifiJU4oiygqtjTRCMYHmNb2c9NvEYbkrAozwIXZS4xH39l7-ET0sZGmb6pZfNEZA/exec'
const svg = loaderSvg
const colors = [
  {
    color: '#8b92fa',
  },
  {
    color: '#b4f8c4',
  },
  {
    color: '#fbfbff',
  },
  {
    color: '#ead685',
  }
]
const mensColors = [
  {
    color: '#fbfbff',
  },
  {
    color: '#0a0101',
  }
]

const questionsArr = MockQuestionsArr
const schedule = mockSchedule

const handleSendData = async (ev) => {
  ev.preventDefault()

  const data = {
    name: formModel.name,
    isCome: formModel.isCome,
    music: formModel.isCome !== 'Не буду' ? formModel.music : '',
    hasChildren: formModel.hasChildren,
    children: formModel.hasChildren !== 'Без детей' ? formModel.children : 0,
    commentary: formModel.commentary
  }

  let sendData = []
  for (let property in data) {
    if (property) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(data[property]);
      sendData.push(encodedKey + "=" + encodedValue);
    }

  }

  sendData = sendData.join("&");

  isButtonDisabled.value = true
  const result = await fetch(URL_APP, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    mode: "cors",
    body: sendData,
  })
    .then((res) => res.json())
    .catch((err) => console.error(err, "Ошибка!"))
  // .then((res) => console.log(res));

  if( result.type === 'success' ) {
    formModel.name = ''
    formModel.music = ''
    formModel.isCome = 'Буду'
    formModel.hasChildren = 'С детьми'
    formModel.children = 1
    formModel.commentary = ''
    showSuccess('Отлично!', 'Анкета успешно отправлена')
  }
  if( result.type === 'error' ) {
    alert(`Ошибка( ${result.errors}`)
  }
  isButtonDisabled.value = false
}

onMounted(() => {
  setTimeout(() => {
    isNamesShown.value = true
  }, 1000)

  const form = document.querySelector("#form")

  form.action = URL_APP

  // form.addEventListener("submit",  (ev) => {
  //   handleSendData(ev)
  // })

  AOS.init()
})

const musicOn = () => {
  isMusicOn.value = !isMusicOn.value
  const audioPlayer = document.getElementsByTagName('audio')[0];
  isMusicOn.value ? audioPlayer.play() : audioPlayer.pause()
}
</script>

<style scoped lang="scss">
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
:deep(.questions__phone-link) {
  color: black;
}
:deep(.el-collapse-item__header) {
  height: 50px;
  font-family: 'Comfortaa', sans-serif;
  line-height: 1.4;
  font-size: 26px;
  @include responsive(md) {
    height: 80px;
    font-size: 34px;
  }
}
:deep(.el-collapse-item__content) {
  padding-top: 16px;
  font-family: 'Comfortaa', sans-serif;
  line-height: 1.4;
  font-size: 20px;
  @include responsive(md) {
    font-size: 28px;
  }
}
.wedding {
  &__music-btn {
    opacity: 0.8;
    z-index: 100;
    width: 32px;
    height: 34px;
    border-radius: 50%;
    position: fixed;
    top: 24px;
    left: 12px;
    background-color: #f7ffff;
  }
}
.main {
  position: relative;
  height: 100vh;
  margin: 0 -12px;
  font-size: 46px;

  &__image-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    line-height: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-family: "Suranna", serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2px;
    @include responsive(md) {
      line-height: 1.2;
    }
    &--top {
      font-size: 92px;
      top: 70px;
      font-weight: 550;
      @include responsive(md) {
        top: 90px;
        font-size: 142px;
      }
    }
    &--bottom {
      font-size: 64px;
      bottom: 56px;
      @include responsive(md) {
        font-size: 80px;
        bottom: 80px;
      }
    }
  }

  &__date {
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 200;
    font-size: 52px;
    letter-spacing: 0.6px;
    @include responsive(md) {
      font-size: 68px;
    }
  }

  &__title {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 72px;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__subtitle {
    position: absolute;
    text-align: center;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    @include responsive(md){
      width: 540px;
    }

  }
}

.description {
  &__text {
    font-family: 'Comfortaa', sans-serif;
  }
  &__image-wrapper {
    margin: 42px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 315px;
  }
  &__image {
    border-radius: 50% 0 50% 0;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }

}

.location {
  &__map {
    display: flex;
    align-items: center;
    justify-content: center;
    iframe {
      margin-top: 24px;
      max-height: 380px;

      @include responsive(lg) {
        max-height: 460px;
      }
    }
  }
}
.dress-code {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 26px;
  }
  &__colors {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 12px;
    gap: 18px;
    flex-direction: row;
    @include responsive(md) {
      gap: 24px;
      padding: 24px 0 14px;
    }
  }
  &__color-item {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    @include responsive(md) {
      width: 58px;
      height: 58px;
    }
  }
}
.schedule {
  display: flex;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 42px;
    @include responsive(lg) {
      flex-direction: row;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    @include responsive(lg) {
      width: 33%;
    }
  }
  &__item-title {
    font-weight: 700;
    margin-bottom: 4px;
  }
  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 1px solid #000;
    margin-bottom: 18px;
  }
}

.survey {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    :deep(.el-loading-spinner) {
      .path {
        stroke: white!important;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__form-checkbox {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    input {
      width: 22px;
      height: 22px;
    }
  }
  :deep(.el-input__wrapper) {
    padding: 8px 12px;
    &.is-focus{
      box-shadow: none;
    }
  }
  :deep(.el-form-item__label) {
    font-weight: 700;
  }
  :deep(.el-textarea__inner:focus) {
    box-shadow: 0 0 0 1px lightgray;
  }
  &__form-button {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: #000;
    color: #fff;
    font-family: 'Comfortaa', sans-serif;
    margin: 16px auto 0;
    @include responsive(md) {
      width: 220px;
    }

  }
}

.time {
  padding-bottom: 64px;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.h2__hr {
  position: absolute;
  left: -1010px;
  top: 50%;
  width: 1000px;
  height: 1px;
  background: #000;
  @include responsive(md) {
    left: -1026px;
  }
}
</style>
